<template>
  <v-card>
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search RefundRequest ID"
          class="invoice-list-search me-3"
        ></v-text-field>

        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="invoice-list-status"
        ></v-select>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="listTable"
      :options.sync="options"
      :server-items-length="totalListTable"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- trending header -->
      <template #[`header.trending`]>
        <v-icon size="22">
          {{ icons }}
        </v-icon>
      </template>

      <!-- id -->
      <template #[`item.id`]="{item}">
        {{ item.id }}
      </template>

      <!-- credits_total -->
      <template #[`item.credits_total`]="{item}">
        {{ item.credits_total | localPrice({ currency: item.currency }) }}
      </template>

      <!-- total -->
      <template #[`item.total`]="{item}">
        {{ item.total | localPrice({ currency: item.currency }) }}
      </template>

      <!-- payment -->
      <template #[`item.payment`]="{item}">
        {{ item.payment.amount | localPrice({ currency: item.currency }) }}
      </template>

      <!-- created_at -->
      <template #[`item.created_at`]="{item}">
        {{ item.created_at | dateTime }}
      </template>

      <!-- Status -->
      <template #[`item.status`]="{item}">
        <!-- chip -->
        <v-chip
          small
          :color="mapColor(item.status)"
          :class="`v-chip-light-bg ${mapColor(item.status)}--text font-weight-semibold`"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ item.created_at | dateTime }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="setItem(item)"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiFileDocumentOutline }}
                </v-icon>
                <span>Details</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-if="refundRequest"
      v-model="showRequest"
      @click:outside="closeRequest"
    >
      <refund-request-approval
        :refund-request="refundRequest"
        @close="closeRequest"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiFileDocumentOutline } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { dateTime, localPrice } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

// composition function
import RefundRequestApproval from '../refund-request-resource/RefundRequestApproval.vue'
import useRefundRequestList from './useRefundRequestList'

export default {
  filters: { localPrice, dateTime },

  components: { RefundRequestApproval },

  setup() {
    const {
      listTable, searchQuery, tableColumns, options, statusFilter, totalListTable, loading, selectedTableData, loadRefundRequests,
    } = useRefundRequestList()

    const statusOptions = ref(['wait_for_acceptance', 'accepted', 'rejected'])

    const refundRequest = ref(null)
    const showRequest = ref(false)

    const setItem = item => {
      refundRequest.value = item
      showRequest.value = true
    }

    const closeRequest = () => {
      refundRequest.value = null
      showRequest.value = false
      console.log('close', refundRequest.value)
      loadRefundRequests()
    }
    const mapColor = status => {
      if (status === 'accepted') return 'success'
      if (status === 'rejected') return ''

      return 'info'
    }

    return {
      tableColumns,
      searchQuery,
      statusOptions,
      statusFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,

      t,

      setItem,
      refundRequest,
      showRequest,
      closeRequest,
      mapColor,

      icons: {
        mdiDotsVertical,
        mdiFileDocumentOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.invoice-list-search {
  max-width: 10.625rem;
}
.invoice-list-status {
  max-width: 11.3rem;
}
</style>
