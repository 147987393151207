<template>
  <v-card>
    <v-card-title>
      Request Refund of {{ refundRequest.total | price }}
      <span v-if="refundRequest.credits_total">(+{{ refundRequest.credits_total | price }} credits)</span>
    </v-card-title>
    <v-card-text v-if="refundRequestData">
      <v-data-table
        :headers="tableColumns"
        :items="refundRequestData.items"
        class="text-no-wrap"
      >
        <template #[`item.total`]="{item}">
          {{ item.total | price }}
        </template>
        <template #[`item.item_total`]="{item}">
          {{ item.item.total | price }}
        </template>
        <template #[`item.sku`]="{item}">
          <span v-if="item.item.sku_snapshot">
            {{ t(item.item.sku_snapshot.sku_name) }} -
            {{ t(item.item.sku_snapshot.product_title) }}
          </span>
          <span v-if="item.item.key">
            {{ item.item.key }}
          </span>
        </template>
      </v-data-table>

      <template>
        <h4>Refund Shipping:</h4>
        <p>{{ refundRequest.shipping_total | price }}</p>
      </template>

      <h4>Refund Reason:</h4>
      <p>{{ refundRequest.reason }}</p>
    </v-card-text>
    <v-card-actions>
      <template v-if="refundRequest.status === 'wait_for_acceptance'">
        <v-btn
          color="success"
          @click="confirmAcceptRefundRequest(refundRequest).then(() => $emit('close'))"
        >
          Accept
        </v-btn>
        <v-btn
          color="warning"
          @click="confirmRejectRefundRequest(refundRequest).then(() => $emit('close'))"
        >
          Reject
        </v-btn>
      </template>

      <template v-else>
        This Request has been {{ refundRequest.status }}.
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useCurrency } from '@/composables';
import { t } from '@/plugins/i18n';
import { price } from '@core/utils/filter';
import useRefundRequest from './useRefundRequest';

export default {
  filters: { price },
  props: {
    refundRequest: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loadRefundRequest, refundRequestData, confirmAcceptRefundRequest, confirmRejectRefundRequest,
    } = useRefundRequest()

    loadRefundRequest(props.refundRequest.id)

    const { baseCurrency } = useCurrency()

    const tableColumns = [
      { text: `Refund ${baseCurrency.value.long_symbol}`, value: 'total' },
      { text: 'Quantity / Credits (pts)', value: 'quantity' },
      { text: 'Items Total', value: 'item_total' },
      { text: 'SKU', value: 'sku' },
    ]

    return {
      tableColumns,
      refundRequestData,
      t,

      confirmAcceptRefundRequest,
      confirmRejectRefundRequest,
    }
  },
}
</script>
