import store from '@/store'
import { acceptRefundRequest, fetchRefundRequest, rejectRefundRequest } from '@api/order'
import { ref } from '@vue/composition-api'

export default function useRefundRequestList() {
  const refundRequestData = ref(null)

  const loadRefundRequest = id => fetchRefundRequest(id)
    .then(({ data }) => {
      refundRequestData.value = data.data
    })
    .catch(error => {
      console.log(error)
    })

  const confirmAcceptRefundRequest = request =>

  // console.log('sku is', sku)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [{
        title: 'Confirm Action',
        content: 'Confirm Accept Refund Request?',
        resolve: () => acceptRefundRequest(request.id).then(resolve),
        resolveText: 'Confirm',
        reject: () => reject(),
        rejectText: 'No',
      }])
    })

  const confirmRejectRefundRequest = request =>

    // console.log('sku is', sku)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [{
        title: 'Confirm Action',
        content: 'Confirm Reject Refund Request?',
        resolve: () => rejectRefundRequest(request.id).then(resolve),
        resolveText: 'Confirm',
        reject: () => reject(),
        rejectText: 'No',
      }])
    })

  return {
    loadRefundRequest,

    refundRequestData,
    confirmAcceptRefundRequest,
    confirmRejectRefundRequest,
  }
}
